@media (min-width: 992px) and (max-width: 1367px) {
  .about_us {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .txt-box {
    background-color: rgb(31, 31, 31);
  }
  /* .din_fea_box {
      width: 230px;
      margin: auto;
    } */
}
@media (min-width: 768px) and (max-width: 991px) {
  .rt-abt-cont {
    width: 452px;
  }
  /* .din_fea_box {
      width: 230px;
      margin: auto;
    } */
}
@media (min-width: 567px) and (max-width: 767px) {
  .navbar .navbar-nav .active:after {
    left: 0px;
    right: 0px;
  }
  .din_fea_box {
    text-align: center;
  }
  .abt_center {
    margin: auto;
  }
  /* .humanoid {
      margin-top: -98px;
    } */
  .working_content .working_img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .roundend_circle {
    bottom: 49px;
  }
  .ev_content .evroundend_circle {
    width: 740px;
    height: 356px;
    background: white;
    border-radius: 493px 0px 9px 493px;
    transform: rotate(313deg);
    position: absolute;
    right: -320px;
    top: -185px;
    z-index: -1;
  }
  .ev_content {
    height: 313px;
  }
  .ev_imag {
    z-index: 1;
    width: 350px;
    margin: auto;
  }
  .human_head {
    width: 228px;
    margin: 25px auto;
  }
  .ev_circle_design {
    left: -52%;
  }
  .ev_circledesign {
    display: none;
  }
  .rt-abt-cont {
    width: 300px;
  }
  .working_Lines {
    border-left: 0px solid #7e7e7e;
    height: 100%;
    display: grid;
  }
  .white_traingle {
    height: 700px;
  }
  .full_robots {
    width: 345px;
  }
  .humanoid_rec {
    width: 580px;
    height: 733px;
    background: white;
    transform: rotate(54deg);
    position: absolute;
    /* right: -132px; */
    top: 62px;
    z-index: -1;
  }
}
@media (min-width: 325px) and (max-width: 566px) {
  .navbar .navbar-nav .active:after {
    left: 0px;
    right: 0px;
  }
  .din_fea_box {
    text-align: center;
  }
  .top-header .rt-line {
    display: none;
  }
  .header_navbar span {
    width: 25px;
  }
  .navbar_show {
    right: 0px !important;
  }
  .about_usposline {
    display: none;
  }
  .working_content {
    display: none;
  }
  .abt_center {
    margin: auto;
  }
  /* .humanoid {
      margin-top: -98px;
    } */
  .ev_circledesign {
    display: none;
  }
  .ev_content {
    height: 313px;
  }
  .triangle_image {
    height: 900px;
  }
  .rt-abt-cont {
    width: 220px;
  }
  .working_Lines {
    border-left: 0px solid #7e7e7e;
    height: 100%;
    display: grid;
  }
  .white_traingle {
    height: 720px;
  }
  .full_robots {
    width: 358px;
    margin-top: -572px;
    margin-left: -828px;
  }
}
@media (max-width: 991px) {
  .left_con {
    display: none;
  }
  .left_cen img {
    width: 180px;
    display: block;
  }
  .left_img {
    padding: 15px;
    height: auto;
  }
  .right_log .form-control {
    margin-bottom: 15px;
  }
  .logr_bg {
    height: 120vh;
  }
  .circles .robo_head {
    width: 120px;
  }
  .carousel_banner .circles {
    width: 300px;
    height: 300px;
  }
  .circles p {
    font-size: 14px !important;
  }
  .carousel_banner .circle_small {
    height: 40px;
    width: 40px;
    left: 126px;
    top: -18px;
  }
  .carousel_banner .circle_img {
    width: 39px;
  }
  .carousel_banner .circle_smalleye {
    height: 40px;
    width: 40px;
    background: white;
    border-radius: 100%;
    position: absolute;
    left: -15px;
    top: 85px;
  }
  .carousel_banner .circle_smallcpu {
    height: 40px;
    width: 40px;
    background: white;
    border-radius: 100%;
    position: absolute;
    bottom: 12px;
    left: 30px;
  }
  .carousel_banner .circle_smallai {
    height: 40px;
    width: 40px;
    background: rgb(255, 255, 255);
    border-radius: 50%;
    position: absolute;
    right: 18px;
    bottom: 23px;
  }
  .carousel_banner .circle_smallvoice {
    height: 40px;
    width: 40px;
    background: white;
    border-radius: 100%;
    position: absolute;
    top: 84px;
    right: -14px;
  }
  .circle_tet {
    position: absolute;
    left: -98px;
    bottom: 2px;
  }
}
@media (max-width: 400px) {
  .right_log {
    padding: 30px;
  }
  .right_log .form-control {
    margin-bottom: 15px;
  }
  .robot-order-det {
    padding: 10px;
  }
  .help-support-content a {
    font-size: 17px;
  }
}
@media (max-width: 767px) {
  .human_head {
    width: 100%;
  }
  .header_navbar .nav_color {
    position: fixed;
    width: 250px;
    top: 135px;
    right: 0;
    width: 250px;
    background: #26262c;
    transition: 0.2s all linear;
    z-index: 111;
    border-left: 3px solid #2a2a30;
    padding: 20px 10px;
    border-radius: 5px;
  }
  .header_navbar .nav_color ul li {
    margin-bottom: 10px;
  }
  .nav-link::before {
    background: none !important;
  }
  .header_navbar .navbar-nav .nav-link {
    transition: 0.2s;
    margin-bottom: 10px;
  }
  .header_navbar .navbar-nav .nav-link:hover {
    background: #fff !important;
    padding-left: 12px;
    border-radius: 5px;
    transition: 0.2s;
    color: #000;
  }
  .header_navbar .nav-link.active {
    background: #fff !important;
    padding-left: 12px;
    border-radius: 5px;
    color: #000 !important;
    width: 100%;
    font-family: "f3";
  }
}
@media (max-width: 767px) {
  .view_pad {
    /* padding: 90px 20px 10px; */
  }
  .location_tab .location_list {
    width: 45%;
    margin-right: 20px;
    margin-bottom: 30px;
  }
  .side_pad {
    padding-right: 0px;
  }
}
@media screen and (min-width: 768px) {
  .view_pad {
    padding-left: calc(var(--nav-width) + 2rem);
    padding-top: 25px;
  }
  .header-admin {
    padding: 0 2rem 0 calc(var(--nav-width) + 2rem);
  }
  .view_pad .header_img {
    width: 40px;
    height: 40px;
  }
  .header_img img {
    width: 45px;
  }
  .l-navbar {
    left: 0;
    padding: 1rem 1rem 0 0;
  }
  .view_pad .show {
    width: calc(var(--nav-width) + 156px);
  }
  .body-pd {
    padding-left: calc(var(--nav-width) + 188px);
  }
}
@media screen and (max-width: 600px) {
  .robo_table table {
    border: 0;
  }
  .robo_table table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .robo_table table tr {
    display: block;
    margin-bottom: 20px;
  }
  .robo_table table td {
    border-bottom: 1px solid #2c2e33;
    display: block;
    font-size: 0.8em;
    text-align: right;
  }
  .robo_table table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  .robo_table table td:last-child {
    border-bottom: 0;
  }
}
@media (max-width: 467px) {
  .location_tab .location_list {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 30px;
  }
} /*# sourceMappingURL=responcive.css.map */
