* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  // overflow: scroll;
}

// // FONTS
// @font-face {
//   font-family: "f1";
//   src: url(../fonts/Euclid\ Circular\ A\ Regular.ttf);
// }

// @font-face {
//   font-family: "f2";
//   src: url(../fonts/Euclid\ Circular\ A\ Medium.ttf);
// }

// @font-face {
//   font-family: "f3";
//   src: url(../fonts/Euclid\ Circular\ A\ SemiBold.ttf);
// }

// @font-face {
//   font-family: "f4";
//   src: url(../fonts/Euclid\ Circular\ A\ Bold.ttf);
// }
.w-50 {
  width: 50% !important;
  margin: auto;
}

.ad_logo {
  width: 180px;
  display: block;
  margin: auto;
}

.left-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-align: center;

  h1 {
    font-family: "f4";
    font-size: 50px;
    color: #fff;
    width: 70%;
    margin: auto;
    margin-bottom: 25px;
  }

  h6 {
    background: #fff;
    border-radius: 5px;
    color: #06a0bb;
    font-family: "f3";
    font-size: 18px;
    padding: 7px 15px;
    display: inline-block;
  }
}

.ad_bg_img {
  background: url(../images/logbg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
}

// Hi Robot Branch
.content {
  width: 100%;
  height: 100%;
  display: none;
}
.content-tabs {
  flex-grow: 1;
}
.active-tabs {
  background: #000;
  border-bottom: 1px solid transparent;
}
.tabs:not(:last-child) {
  border-right: 1px solid transparent;
}
.active-tabs::before {
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translate(-50%);
  // width:calc(100%+2px);
  height: 5px;
  background: #0090e7;
}
.bloc-tabs .tabs {
  padding: 5px 15px;
  border-radius: 10px;
  color: #25aae1;
  margin: 0px 3px 3px 0px;
}

.bg-dar {
  background-color: #2a3038;
}
.active-content {
  display: block;
}
.head-text {
  color: #25aae1;
  font-size: 18px;
  font-family: "f2";
}
.tabbs {
  background-color: #2a3038;
  border-radius: 10px;
  padding: 20px;
}
.keybtn {
  background-color: #000;
  border-radius: 10px;
  padding: 2px 0px 2px 5px;
}

// LANDING PAGE
.landing-page-container {
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .gradient {
    width: 100%;
    height: 100vh;
    background-color: #000;
    opacity: 40%;
    position: absolute;
    top: 0;
  }

  .content-wrapper {
    position: absolute;
    text-align: center;

    h1 {
      font-family: "f4";
      color: #ffda30;
      font-size: 50px;
    }

    h2 {
      font-family: "f4";
      color: #fff;
      font-size: 50px;
      margin-bottom: 20px;
    }

    h3 {
      font-size: 30px;
      font-family: "f2";
      color: #fff;
      margin-bottom: 50px;
    }

    .link {
      background: #d3000b;
      color: #fff;
      font-size: 25px;
      font-family: "f2";
      padding: 10px 15px;
      border-radius: 10px;
      text-decoration: none;
    }
  }
}

// HEADER

.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
  }

  h1 {
    font-size: 40px;
    position: absolute;
    font-family: "f4";
    color: #fff;

    span {
      color: #ffda30;
    }
  }
}

// CATEGORY COMP

.category {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding: 18px 15px;
  margin-left: 8px;
  margin-right: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // padding: 12px 0;
  transition: 0.3s;

  &:hover {
    background-color: #ffda30;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 13%);

    h3 {
      color: #fff;
    }
  }

  img {
    // width: 66%;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    text-align: center;
    margin-bottom: 5px;
  }

  h3 {
    margin-top: 10px;
    font-family: "f4";
    font-size: 16px;
    color: #000;
  }
}

.selected {
  background-color: #ffda30;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 13%);

  h3 {
    color: #fff;
  }
}

// CATEGORIES

.menu-wrapper {
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;

  .categories {
    // width: 100%;
    // height: 150px;
    position: sticky;
    position: -webkit-sticky;
    top: -39%;
    background-color: #fff;
    z-index: 10;

    .category-tile {
      // width: 100%;
      margin-top: 20px;
      margin-left: 10px;
      margin-right: 10px;
      display: flex;
      flex-direction: row;
      overflow-y: hidden;
      overflow-x: scroll;
      box-sizing: border-box;
    }

    h2 {
      font-family: "f4";
      font-size: 20px;
      color: #d3000b;
      margin-left: 15px;
      margin-top: 20px;
    }

    p {
      color: #000;
    }
  }

  .food-items {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
    width: 100%;
    height: 100%;
    padding: 0 10px;
  }
}

// FOOD ITEM

.food-item {
  width: 31.5%;
  height: 10%;
  border: 1px solid #a4a4a4;
  border-radius: 10px;
  padding: 10px;
  margin-left: 1.5%;
  margin-bottom: 1.9%;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    border-radius: 10px;
    width: 100%;
    height: 180px;
    object-fit: contain;
    margin-bottom: 20px;
  }

  .food-name-price {
    width: 100%;

    p {
      font-family: "f3";
      font-size: 15px;
      color: #000;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      display: -webkit-box;
      margin-bottom: 15px;
    }

    .food-price {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        margin: 0;
        color: #d3000b;
        font-family: "f3";
        font-size: 16px;
      }
    }
  }
}

// ADD BUTTON

.addBtn {
  border: 2.4px solid #d3000b;
  padding: 3px 12px;
  margin-bottom: 10px;
  border-radius: 4px;
  color: #d3000b;
  font-family: "f3";
  transition: 0.3s;

  &:hover {
    background-color: #d3000b;
    color: #fff;
  }
}

.product-qty {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60px;
  height: 30px;
  margin-bottom: 10px;
}

// CHECKOUT

.checkout {
  background: #ffda30;
  border-radius: 10px;
  margin-top: 30px;
  position: sticky;
  bottom: 10px;
  right: 10px;
  padding: 15px;
  width: 44%;
  height: 10%;
  margin: 20px 0px 0px auto;
  font-family: "f3";
  font-size: 18px;
}

.checkout-btm {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 0 15px;
  // margin-bottom: 30px;
}

.checkout-btn {
  width: 40%;
  font-family: "f3";
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    margin-top: 17px;
    border-radius: 10px;
    background-color: #000;
    padding: 13px 25px;
    text-decoration: none;

    &:first-of-type {
      // margin-right: 30px;
      // border: 2px solid #ffda30;
      background-color: #ffda30;

      .link {
        color: #fff;
        text-decoration: none;
      }
    }

    &:last-of-type {
      background-color: #d3000b;

      .link {
        color: #fff;
        text-decoration: none;
      }
    }
  }
}

.dropdownSelect {
  width: 50%;
  border: 1;
  border-color: #fff;
  border-radius: 5px;
  background: none;
  color: #0090e7;
  font-family: "f4";
  font-size: 15px;
  padding: 5px;
  margin: 0px 12px;
}

// CHECKOUT PAGE

.checkout-page {
  .order-value {
    display: flex;
    justify-content: space-between;
  }

  p {
    font-family: "f4";
    font-size: 25px;
    color: #d3000b;
    margin: 20px;
  }
}

// CHECKOUT ITEM

.checkout-item {
  margin: 0 20px;
  background: #fff;
  border: 1px solid #a4a4a4;
  padding: 10px 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .thumbnail {
    width: 200px;
    height: 150px;
    text-align: center;

    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }

  p {
    flex: 1;
    text-align: center;
    font-size: 18px;
    margin: 0;
    font-family: "f3";
    color: #000;

    &.price {
      color: #d3000b;
    }
  }

  .counter {
    flex: 0.4;
    display: flex;
    align-items: center;

    p {
      color: #d3000b;
      margin: 0 6px;

      &:first-of-type,
      &:last-of-type {
        padding: 3px 12px;
        border: 1px solid #d3000b;
        border-radius: 7px;
        cursor: pointer;
        font-size: 24px;
      }
    }
  }

  .counters {
    flex: 0.4;
    display: flex;
    align-items: center;

    p {
      color: #d3000b !important;
      margin: 0 6px;
    }
  }

  .close-btn {
    padding: 10px 14px;
    font-family: "f4";
    background-color: #d3000b;
    color: #fff;
    border-radius: 50%;
  }
}

// SUCCESS POPUP

.success-popup {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "f4";
  padding: 15%;

  video {
    width: 250px;
    height: 250px;
    margin-bottom: 15px;
    background-color: #a4a4a4;
  }

  h1 {
    font-size: 30px;
    text-align: center;
  }

  .back-btn {
    background: #d3000b;
    border-radius: 10px;
    padding: 15px 30px;
    font-size: 16px;
    font-family: "f3";
    color: #fff;
    margin-top: 12px;
  }
}

// SELECT TABLE
.tables {
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  width: 90%;
  margin: auto;
  margin-top: 20px;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    height: 170px;
    border: 1px solid #a4a4a4;
    font-family: "f1";
    font-size: 16px;
    position: relative;

    img {
      width: 80%;
      height: 80%;
      object-fit: contain;
    }

    .table-name {
      // width: 100%;
      // height: 100%;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background-color: #fff;
      width: 35px;
      height: 35px;
      border-radius: 100px;

      p {
        margin: 0;
        font-family: "f2";
        font-size: 16px;
      }
    }

    &.active {
      background-color: #ffd930c5;
      border: none;
      font-family: "f2";
      font-size: 18px;
    }
  }
}

.table-btn-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    text-align: center;
    background: #d3000b;
    border-radius: 10px;
    padding: 15px 30px;
    font-size: 16px;
    font-family: "f3";
    color: #fff;
    margin-top: 12px;
  }
}

.websocket-popup {
  background-color: #000;
  width: 100%;
  height: 100vh;
  position: sticky;
  top: 0;
  bottom: 0;
  z-index: 100;

  img {
    width: 100%;
    height: 100%;
  }
}

.cart {
  position: fixed;
  background-color: #d3000b;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  right: 30px;
  bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  // padding: 5px;
  font-size: 16px;
  font-family: "f3";

  p {
    position: absolute;
    // color: #000;
    top: 9px;
    right: 11px;
  }
}

/* login page starts */

.login {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    width: 100%;
  }
}

.log_bg {
  background: #fff !important;
  height: 100vh;
  overflow: hidden;
}

.left_img {
  // background: url(../images/header/logbg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
}

.left_cen {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-align: center;
}

.left_cen img {
  display: none;
}

.left_con img {
  width: 180px;
  display: block;
  margin: auto;
}

.left_con h1 {
  font-size: 50px;
  color: #fff;
  font-family: "f3";
  width: 70%;
  margin: auto;
  margin-bottom: 25px;
}

.left_con h6 {
  background: #fff;
  border-radius: 5px;
  color: #d3000b;
  font-family: "f3";
  font-size: 18px;
  padding: 7px 15px;
  display: inline-block;
}

.right_log {
  padding: 50px 80px;

  .form {
    margin-top: 30px;
    width: 80%;
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: center;
  }
}

// .right_log h1 {
//   color: #d3000b;
//   font-family: "f3";
//   font-size: 30px;
//   margin-bottom: 20px;
// }
.right_log h1 {
  color: #25aae1;
  font-family: "f4";
  font-size: 30px;
  margin-bottom: 20px;
}

.right_log p {
  font-size: 14px;
  font-family: "f3";
  color: #000;
}

.right_log input::placeholder {
  color: #bebebe;
}

.right_log {
  .form-cl {
    background-color: #fff !important;
    border-radius: 10px;
    box-shadow: 0px 5px 10px rgb(0 16 235 / 12%);
    padding: 15px 25px;
    border: 0 !important;
    margin-bottom: 30px;
    color: #000;
    font-size: 14px;
  }

  .color-cust {
    color: #bebebe !important;
  }
}

.login_btn {
  // margin: 40px 0px 30px;
  margin: 20px 0px 20px;
}

// .bt_sub {
//   background: #d3000b;
//   border-radius: 30px;
//   padding: 10px 40px;
//   color: #fff !important;
//   font-family: "f3";
//   border: 0;
//   // margin-bottom: 20px;
//   text-decoration: none;
// }

.bt_sub {
  background: #25aae1;
  border-radius: 30px;
  padding: 10px 40px;
  color: #fff !important;
  font-family: "f4";
  font-size: 16px;
  border: 0;
  margin-bottom: 20px;
}

.bt_center p {
  text-align: center;
  font-size: 14px;
}

.bt_center button {
  border: none;
  background-color: #fff;
  color: #d3000b !important;
  font-family: "f3";
  text-decoration: none;
}

.logr_bg {
  background: #fff !important;
  height: 100vh;
  overflow: hidden;
}

/* login page ends */

// ADMIN PANEL

// @import "./constant/Font.scss";
// @import "./constant/color.scss";

:root {
  --header-height: 3rem;
  --nav-width: 68px;
  --bs-body-bg: #000;
}

.err-state {
  color: #fc424a;
  position: absolute;
  bottom: -15px;
  left: 20px;
}

.header-admin {
  padding: 0 2rem 0 calc(var(--header-height) + 2rem);
}

.user_head {
  width: 100%;
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 6rem;
  background-color: #191c24;
  z-index: 100;
  transition: 0.5s;
}

.user_head .nav_logo img {
  width: 90px;
  margin-left: 15px;
}

.menu_icon {
  color: #fff;
}

.user_head .header_img {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
}

.view_pad {
  margin: calc(1rem + 1rem) 0 0 0;
  // padding-left: calc(68px + 2rem);
  padding-top: 25px;
}

.navlink2 {
  width: 100% !important;
}

.user-side-nav .nav_link.active {
  color: #fff;
}

.l-navbar {
  position: fixed;
  top: 0;
  // left: -30%;
  left: 0;
  // right: 0;
  width: var(--nav-width);
  height: 100vh;
  background-color: #191c24;
  padding: 0.5rem 1rem 0 0;
  z-index: 100;
  transition: 0.5s !important;
}

.view_pad .show {
  width: calc(var(--nav-width) + 156px) !important;
  left: 0 !important;
}

.l-navbar .nav {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // overflow: hidden;
}

.l-navbar .nav_link {
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  column-gap: 1rem;
  padding: 0.5rem 0 0.5rem 1.5rem;
}

/* .nav_logo-icon {
    font-size: 1.25rem;
    color: #fff;
  } */

/* .nav_logo-name {
    color: #fff;
    font-weight: 700
  } */

.l-navbar .nav_link {
  position: relative;
  color: #25aae1;
  margin-bottom: 15px;
  transition: 0.5s;
  font-family: "f2";
  font-size: 16px;
}

.l-navbar .nav_link:hover {
  color: #fff;
}

.l-navbar .nav_icon {
  font-size: 18px;
}

.side-content {
  color: #fff;
  padding-left: 100px;
  // padding-left: 250px;
  background-color: #000;
  min-height: 100vh !important;
  height: 100%;
  padding-top: 1.5rem !important;
  // padding-bottom: 38rem;
}

.side-content2 {
  color: #fff;
  // padding-left: 100px;
  padding-left: 250px;
  background-color: #000;
  min-height: 100vh !important;
  height: 100%;
  padding-top: 1.5rem !important;
  // padding-bottom: 38rem;
}

.side_pad {
  padding-right: 30px;
}

/* menu starts */

.menu-list h1 {
  font-family: "f2";
  font-size: 25px;
  color: #25aae1;
  margin-bottom: 15px;
}

.table-responsive {
  overflow-x: auto;
}

.menu-table table {
  border: 1px solid #ffffff;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  /* table-layout: fixed; */
  // margin-bottom: 30px;
}

.menu-table table tr {
  background: #191c24;
  border: 1px solid #2c2e33;
}

.menu-table .btn-success {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  font-family: "f1";
}

.menu-table table th {
  font-size: 18px;
  color: #25aae1;
  font-family: "f3";
}

.menu-table img {
  width: 100px !important;
  height: 80px !important;
}

.menu-table table th,
.menu-table table td {
  padding: 15px 10px;
  font-family: "f2";
  text-align: center;
}

.add-food-section {
  background: #191c24;
  border-radius: 10px;
  padding: 20px;
}

.add-food-section .form-control {
  background: #2a3038 !important;
  border: none;
  padding: 10px 15px;
  font-size: 16px;
  font-family: "f2";
  margin-bottom: 20px;
  color: #fff !important;
}

.add-food-section label {
  font-family: "f3";
  font-size: 18px;
  margin-bottom: 15px;
}

.add-food-section input::placeholder {
  color: #6c7293;
}

.add-food-section .btn-submit {
  background: #0090e7 !important;
  padding: 10px 15px;
  color: #fff;
  font-family: "f3";
  border: 0;
  border-radius: 5px;
}

// .view_pad .show {
//     left: 0;
// }

/* menu ends */
// menu screen start
.menu_section {
  background-image: linear-gradient(to left, #d41459, #911a6c);
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 30px;
}

/* .menu_section img{
    width: 100%;
  } */
.menu_section h5 {
  font-size: 18px;
  font-family: "f2";
}

.menu_section p {
  font-size: 16px;
  font-family: "f1";
  margin: 0;
}

.btn-outline {
  font-family: "f2";
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 2px solid #fff;
  color: #fff;
  transition: 0.3s;
  background: transparent;
}

.btn-outline:hover {
  background: #fff;
  color: #000;
  transition: 0.2s;
}

.btn-outline:hover {
  color: #000;
}

.btn-success:hover {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
}

// menu screen end

// Toggle start
.logo-toggle {
  padding-left: 9.5rem;
  transition: 0.5s;
}

// Toggle end

// ROBOT LIST

.view_map {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.view_map .tab_view {
  background: #0090e7;
  border-radius: 5px;
  padding: 5px 15px;
  font-family: "f3";
  color: #fff;
  margin-bottom: 20px;
}

.side_pad {
  padding-right: 30px;
}

.robo_table table {
  border: 1px solid #2c2e33;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
  margin-bottom: 30px;
}

.robo_table table tr {
  background: #191c24;
  border: 1px solid #2c2e33;
  padding: 0.35em;
}

.robo_table table th,
.robo_table table td {
  padding: 15px 10px;
  font-family: "f2";
}

.robo_table table th {
  font-size: 18px;
  color: #25aae1;
  font-family: "f3";
}

.ac_ic {
  color: #00d25b;
  margin-right: 5px;
}

.ac_id {
  color: #0dcaf0;
  margin-right: 5px;
}

.ac_ie {
  color: #ffc107;
  margin-right: 5px;
}

.dec_ic {
  color: #fc424a;
  margin-right: 5px;
}

.robo_table .tab_view {
  background: #0090e7;
  border-radius: 2px;
  padding: 5px 15px;
  font-family: "f3";
  color: #fff;
}

.robo_table table th,
.robo_table table td {
  padding: 15px 10px;
  font-family: "f2";

  svg {
    width: 16px;
    height: 16px;
  }
}

.robot_mapping_detail .tab_view {
  background: #0090e7;
  border-radius: 5px;
  padding: 10px 15px;
  font-family: "f3";
  color: #fff;
  border: none !important;
}

.menu_section {
  background-image: linear-gradient(to left, #d41459, #911a6c);
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 30px;
}

/* .menu_section img{
    width: 100%;
  } */
.menu_section h5 {
  font-size: 18px;
  font-family: "f3";
}

.menu_section p {
  font-size: 16px;
  font-family: "f2";
  margin: 0;
}

.btn-outline:hover {
  background: #fff;
  color: #000;
  transition: 0.2s;
}

.btn-outline:hover a {
  color: #000;
}

.robot-details h6 {
  font-family: "f3";
  font-size: 20px;
  margin-bottom: 15px;
}

/* robot detail end */

/* robot order details starts */

.robot-order-det {
  background: #191c24;
  padding: 20px;
  border-radius: 6px;

  p {
    font-size: 16px;
  }
}

.robot-order-det .robot_name h5 {
  font-family: "f2";
  font-size: 14px;
  color: #6c7293;
  margin: 0;
}

.robot-order-det .robot_name span {
  color: #fff;
  font-family: "f3";
}

.robot-order-det .robot_content p {
  color: #6c7293;
}

.robot-order-det .robot_content span {
  color: #fff;
  font-family: "f3";
}

.robot_content p {
  margin: 0;
}

.mar_b {
  margin-bottom: 15px;
}

//   order details end

// DELETE POPUP
.popup-wrap {
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-innerbox {
  background-color: rgb(25, 28, 36);
  width: 38%;
  height: 30%;
  border-radius: 15px;
  display: flex;
  align-items: center;
  padding: 30px;
  flex-direction: column;
  justify-content: center;

  p {
    font-size: 16px;
    font-family: "f2";
    text-align: center;
    color: #fff;
  }

  div {
    width: 80%;
    display: flex;
    justify-content: space-evenly;
    margin-top: 10px;

    button {
      margin: 0;
      border: 0;
      padding: 6px 20px;
      border-radius: 6px;
      font-size: 16px;
      font-family: "f3";
      color: #fff;

      &:first-of-type {
        background-color: #fc424a;
      }

      &:last-of-type {
        background-color: #6c7293;
      }
    }
  }
}

.popup-pass {
  position: fixed !important;
  top: calc(100vh - 300px) !important;
}

.key_board {
  position: fixed;
  bottom: 0;
  width: 100%;
}

//radio-btn-start
.box1 {
  width: 19px;
  height: 19px;
  border: 1px solid #d3000b;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box11 {
  width: 18px;
  height: 18px;
  background-color: #d3000b;
}

//radio-btn-end

// TABLE REACT CUSTOMIZE START
.table-dark {
  --bs-table-color: #fff !important;
  --bs-table-bg: #191c24 !important;
  --bs-table-border-color: #4d5154 !important;
  --bs-table-striped-bg: #2c3034 !important;
  --bs-table-striped-color: #fff !important;
  --bs-table-active-bg: #373b3e !important;
  --bs-table-active-color: #fff !important;
  --bs-table-hover-bg: #323539 !important;
  --bs-table-hover-color: #fff !important;
  color: var(--bs-table-color) !important;
  border-color: var(--bs-table-border-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color) !important;
  --bs-pagination-bg: #191c24 !important;
  --bs-pagination-border-width: var(--bs-border-width) !important;
  --bs-pagination-border-color: #191c24 !important;
  --bs-pagination-border-radius: var(--bs-border-radius) !important;
  --bs-pagination-hover-color: var(--bs-link-hover-color) !important;
  --bs-pagination-hover-bg: #191c24 !important;
  --bs-pagination-hover-border-color: var(--bs-border-color) !important;
  --bs-pagination-focus-color: var(--bs-link-hover-color) !important;
  --bs-pagination-focus-bg: #191c24 !important;
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25) !important;
  --bs-pagination-active-color: #191c24 !important;
  --bs-pagination-active-bg: #0d6efd;
  --bs-pagination-active-border-color: #0d6efd;
  --bs-pagination-disabled-color: rgb(40 96 152 / 75%) !important;
  --bs-pagination-disabled-bg: #191c24 !important;
  --bs-pagination-disabled-border-color: #191c24 !important;
  display: flex;
  padding-left: 0;
  list-style: none;
}

// TABLE REACT CUSTOMIZE END

.headeBtn {
  border: none;
  background: transparent;
  color: #fff;
  font-size: 14px;
  padding: 5px;

  svg {
    font-size: 18px;
  }
}

.headeBtn:hover {
  color: #25aae1 !important;
}

.headeBtnActive {
  border: none;
  background: transparent;
  color: #25aae1;
  font-size: 14px;
  padding: 5px;

  svg {
    font-size: 18px;
  }
}

.global-filter {
  background: #191c24 !important;
  border-radius: 10px !important;

  input {
    background: #2a3038 !important;
    border: none;
    padding: 10px 15px;
    font-size: 16px;
    font-family: "f2";
    margin-bottom: 20px;
    color: #fff !important;
  }

  svg {
    background: #2a3038 !important;
    margin-bottom: 20px;
    margin-left: 20px;
    // padding: 10px 15px;
  }
}

.global-filter input::placeholder {
  color: #6c7293;
}

.header_img img {
  width: 45px;
}
